<template>
    <div
      class="mediaBg"
     
    >
      <div class="w1364">
        <div class="media" v-for="(columnData, columnKey, columnIndex) in mediaRows" :key="columnKey">
          <div class="media_title">
            <p>
              {{ columnData.title}}
            </p>
            <span class="seeMore" @click="seeMore(columnData.columnId,columnIndex)">查看更多</span>
          </div>
          <ul>
            <li
              v-for="(item, index) in columnData.rows"
              :key="index"
              style="cursor: pointer"
              @click="toDetail(item)"
            >
              <p class="title">
                <span class="toRight">{{
                  $moment(item.publishTime).format("YYYY-MM-DD")
                }}</span>
              </p>
              <div class="bottom">
                <span class="ellipsis">{{ item.title }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  <script>
  const bg369 = require("../assets/img/教育教学369.png");
  const bg632 = require("../assets/img/510.png");
  export default {
    name: "WgMedia",
    props: {
      columnIds: {
        type: Array,
        default: () => {
          return [];
        },
      },
      title: {
        type: Array,
        default: () => {
          return [];
        },
      },
      maxLength: {
        type: Number,
        default: () => {
          return 3;
        },
      },
    },
    data() {
      return {
        datas: {},
        bg369,
        bg632,
        mediaRows:[]
      };
    },
    methods: {
      toDetail(item) {
        this.$emit("toDetail", item);
      },
      seeMore(columnId,columnIndex) {
        this.$emit("seeMore", { columnId:columnId, title: this.title[columnIndex] });
      },
      async getHomeRotographListFun(val){
        const params = {
          pageNumber: 0,
          pageSize: 10,
          columnIds: val,
        };
        const res = await this.API.basic.getHomeRotographList(params);
        // this.datas[val.toString()] = res.data.content.slice(0, this.maxLength);
        this.mediaRows.forEach(item=>{
            if(item.columnId===val){
              item.rows = res.data.content.slice(0,this.maxLength);
            }
          })
        this.$forceUpdate()
      }
    },
    watch: {
      columnIds: {
        async handler(val) {
          if (val) {
            // this.getHomeRotographListFun(val[0])
            // this.getHomeRotographListFun(val[1])
            val.forEach((item,index) => {
               let obj={
                columnId:item,
                title:this.title[index]
               }
               this.mediaRows.push(obj)
                this.getHomeRotographListFun(item)
              })
          }
        },
        immediate: true,
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .mediaBg {
    background: url("../assets/img/党群工会680.png") no-repeat;
    background-size: 100% 100%;

    .w1364 {
      padding: 30px 0px 0px !important;
      display: flex;
      justify-content: space-between;
    }
  }
  .media {
    width: 672px;
    overflow: hidden;
  }
  .media_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .media_title p {
    font-weight: 500;
    font-size: 28px;
    color: #000000;
  }
  .media_title p span {
    margin-left: 12px;
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    opacity: 0.4;
  }
  .media ul {
    list-style: none;
    /* margin-left: 100px; */
    /* margin-right: 100px; */
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }
  .media ul li:hover {
    background: #c03530;
    .title {
      color: #ffffff;
    }
    .bottom {
      color: #ffffff;
      .toRight {
        color: #ffffff;
      }
    }
  }
  .media ul li {
    width: 672px;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 4px 68px 0px rgba(145, 155, 171, 0.13);
    border-radius: 8px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    line-height: 35px;
    padding: 20px 26px;
  
    .title {
      font-weight: 400;
      font-size: 20px;
      color: #000;
      //margin-bottom: 40px;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 20px;
      color: #333333;
      .toRight {
        font-size: 20px;
        color: #666666;
      }
    }
  }
  .seeMore {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    opacity: 0.4;
    cursor: pointer;
  }
  </style>
  