<template>
  <div class="news_infoBg">
  <div class="news_info w1364">
    <div class="news_info_title">
      <p>{{ title }}
<!--        <span>NEWS</span>-->
      </p>
      <span class="seeMore" @click="seeMore">查看更多</span>
    </div>
    <div class="center">
      <div class="news_info_sw"  v-if="datas.length > 0 && this.college !=='d9cdydbdh'"  @click="toDetail(datas[0])">
        <img class="topSw" :src="datas[0].thumbnailInfo.thumbnailUrl" alt="" />
        <div class="topContent">
          <div class="title">
            {{ $moment(datas[0].publishTime).format("YYYY-MM-DD") }}
          </div>
          <div class="content">
            <div class="ellipsis2">{{ datas[0].title }}</div>
          </div>
        </div>
      </div>
      <div class="swiper" v-if="this.college === 'd9cdydbdh'">
                <swiper :options="swiperOptions" v-if="datas.length">
                    <swiper-slide v-for="(slide, index) in datas.slice(0,3)" :key="index" >
                        <img @click="toDetail(slide)"   :src="slide.thumbnailInfo.thumbnailUrl" alt="">
                        <p >{{ slide.title }}</p>
                    </swiper-slide>
                </swiper>
              <!-- 分页器 -->
              <!-- <div id="jjswiper" class="swiper-pagination"></div> -->
            </div>
      <div class="news_info_list" v-if="datas.length > 1">
        <div
          class="listItem"
          v-for="(item, index) in datas.slice(this.college === 'd9cdydbdh' ? 3:1, this.college === 'd9cdydbdh' ? 8:6)"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="timeItem">
<!--            <p>-->
<!--              {{ $moment(item.publishTime).format("DD") }}-->
<!--            </p>-->
            <p>
              {{ $moment(item.publishTime).format("YYYY-MM-DD") }}
            </p>
          </div>
          <div class="zxcontent">
            <p class="ellipsis2">
              {{ item.title }}
            </p>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: "WgNews",
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      datas: [],
      swiperOptions: {
                effect: 'fade',
                loop: true,
                autoplay: {
                  delay: 3000, // 自动播放间隔时间，单位是毫秒
                  disableOnInteraction: false, // 用户操作后是否停止自动播放
                },
                centeredSlides: true,
                pagination: {
                    el: '#jjswiper',
                    type:'bullets',
                    renderBullet: function(index, className) {
                      return '<span class="' + className + '">' + (index + 1) + '</span>';
                    },
                    clickable: true, // 使分页器可点击
                    bulletClass: 'custom-bullet-page',
                    bulletActiveClass: 'custom-bullet-active-page',
                },
            },
            college: this.$store.state.college,
    };
  },
  methods: {
    toDetail(item) {
      this.$emit("toDetail", item);
    },
    seeMore() {
      this.$emit("seeMore", { columnId: this.columnIds, title: this.title });
    },
  },
  watch: {
    columnIds: {
      async handler(val) {
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);

          // this.datas = res.data.content.slice(0, 5);
          // this.datas = [...this.datas, this.datas[0]];
          // if(this.college === 'd9cdydbdh'){
          //   this.datas = res.data.content.reverse();

          // }else{
            this.datas = res.data.content;
          // }
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.news_infoBg{
  background: url("../assets/img/新闻1-652.png") no-repeat;
  background-size: 100% 100%;
  .w1364{
    padding: 30px 0px!important;
  }
}
.news_info {
  padding: 30px 268px 30px 271px;
  width: 100%;
  overflow: hidden;
}
.news_info_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.news_info_title p {
  font-weight: 500;
  font-size: 28px;
  // color: #000000;
  color:rgb(161, 41, 22);

  /* margin-left: 100px; */
}
.news_info_title p span {
  margin-left: 12px;
  //font-family: PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  opacity: 0.4;
}
.seeMore {
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  opacity: 0.4;
}
.news_info .center {
  display: flex;
}
.news_info_sw {
  flex-shrink: 0;
  width: 678px;
  // height: 679px;
  background: #ffffff;
  box-shadow: 0px -9px 38px 0px rgba(207, 220, 232, 0.3);
  background: #fff;
  position: relative;
  cursor: pointer;
  border-bottom: 4px solid transparent;
}
.news_info_sw .topSw {
  width: 100%;
  height: 382px;
}
.news_info_sw .topContent {
  padding: 17px;
  .title {
    // font-family: Gotham;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin-bottom: 10px;
    padding-left: 4px;
  }
  .content {
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    line-height: 42px;
    //margin-bottom: 53px;
  }
  .point {
    margin-right: 10px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #f5e9e7;
    border-radius: 50%;
    // opacity: 0.1;
    &.active {
      background: #a12916;
    }
  }
}

.news_info_list {
  margin-left: 77px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.news_info_list .listItem {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 4px solid #fff;
  height: 90px;
  //margin-bottom: 20px;
  //padding-bottom: 34px;
  &:last-child {
    margin-bottom: 0;
  }
}
.ellipsis2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
// .news_info_list .listItem {
//   color: #bd2911 !important;
// }
.timeItem {
  width: 160px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid rgb(161, 41, 22);
  margin-right: 25px;
  text-align: center;
  color: #000;
  //margin-top: 30px;
}
.timeItem p:first-child {
  // font-family: Gotham;
  font-weight: normal;
  //font-size: 30px;
  line-height: 30px;
  margin-top: 8px;
  font-size: 20px;
  //color: rgba(0,0,0,.4);
  color: rgb(161, 41, 22);
}
.timeItem p:nth-child(2) {
  // font-family: Gotham;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  margin-top: 10px;
}
.listItem:hover {
  border-bottom: 4px solid #a12916;
}
.news_info_sw:hover {
  border-bottom: 4px solid #a12916;
}
.news_info_list .zxcontent {
  //margin-top: 16px;
  padding-left: 10px;
  padding-right: 0px;
  width: calc(100% - 118px);
  //font-family: PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #000;
}
.swiper{
  position: relative;
  width: 678px !important;
}
.swiper-container {
/**   width: 1070px;
     height: 350px; */
  width: 100%;
  height: 100%;
}
::v-deep .swiper-slide{
  width: 678px !important;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
::v-deep .swiper-slide p{
    position: absolute;
    width: 100%;
    color: #fff;
    box-sizing: border-box;
    padding: 10px;
    bottom: 0;
    background-color: #0000008e;
}
::v-deep .swiper-slide img{
    width: 100%;
    height: 100%;
}
::v-deep .swiper-pagination{
    bottom: 10px;
   
    right: 0;
}
::v-deep .custom-bullet-page{
    width: 20px;
    display: inline-block;
    border: 1px solid #fff;
    margin: 0 5px;
    color:#fff;
    cursor: pointer;
}
::v-deep .custom-bullet-active-page{
  background: #f60;
  border: 1px solid #f60;
}
</style>
