<template>
  <div class="person_infoBg">
    <div class="person_info w1364">
      <div class="info_title">
        <p>
          {{ title }}
          <!--        <span>Academic dynamics</span>-->
        </p>
        <span class="seeMore" @click="seeMore">查看更多</span>
      </div>
      <div class="content">
        <div
          class="item"
          v-for="(item, index) in datas.slice(showIndex, showIndex + 3)"
          :key="index"
          @click="toDetail(item)"
        >
          <!--        <div class="time">-->
          <!--          <div class="day">-->
          <!--            {{ $moment(item.publishTime).format("DD") }}-->
          <!--          </div>-->
          <!--          <div class="year">-->
          <!--            {{ $moment(item.publishTime).format("YYYY-MM") }}-->
          <!--          </div>-->
          <!--        </div>-->

          <img :src="item.thumbnailInfo.thumbnailUrl" alt="" />
          <div style="font-size: 1.04167vw; margin-top: 0.52083vw">
            {{ $moment(item.publishTime).format("YYYY-MM-DD") }}
          </div>
          <div class="title">
            <div class="ellipsis2">{{ item.title }}</div>
          </div>
          <div class="bottom"></div>
        </div>
      </div>
      <div class="btns">
        <el-button
          icon="el-icon-arrow-left"
          circle
          @click="changePage(false)"
        ></el-button>
        <el-button
          type="danger"
          icon="el-icon-arrow-right"
          circle
          @click="changePage(true)"
        ></el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WgPerson",
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      datas: [],
      showIndex: 0,
    };
  },
  methods: {
    toDetail(item) {
      this.$emit("toDetail", item);
    },
    seeMore() {
      this.$emit("seeMore", { columnId: this.columnIds, title: this.title });
    },
    changePage(type) {
      if (type) {
        this.showIndex + 3 >= this.datas.length
          ? (this.showIndex = this.showIndex)
          : this.showIndex++;
      } else {
        this.showIndex - 1 < 0
          ? (this.showIndex = this.showIndex)
          : this.showIndex--;
      }
    },
  },
  watch: {
    columnIds: {
      async handler(val) {
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);
          this.showIndex = 0;
          this.datas = res.data.content;
        }
      },
      immediate: true,
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.person_infoBg {
  background: url("../assets/img/bg5.png") no-repeat;
  background-size: 100% 100%;
  .w1364{
    padding: 30px 0px!important;
  }
}
.person_info {
  padding: 30px 268px 30px 271px;
  width: 100%;
  // overflow: hidden;
  position: relative;
  .btns {
    position: absolute;
    left: 95%;
    bottom: 30px;
    display: flex;
  }
  .info_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-bottom: 20px;
    p {
      font-weight: 500;
      font-size: 28px;
      color: #000000;
      /* margin-left: 100px; */
    }
    p span {
      margin-left: 12px;
      // font-family: PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
      opacity: 0.4;
    }
    .seeMore {
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      opacity: 0.4;
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    gap: 35px;
    .item {
      cursor: pointer;
      .time {
        border-radius: 8px;
        border: 2px solid #000000;
        opacity: 0.6;
        padding: 6px;
        width: 87px;
        height: 87px;
        .day {
          //  font-family: PingFang SC;
          font-weight: 400;
          font-size: 28px;
          color: #000000;
          text-align: center;
        }
        .year {
          text-align: center;
          //  font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
        }
      }
      img {
        width: 433px;
        height: 270px;
        margin-top: 27px;
      }
      .title {
        font-weight: 400;
        font-size: 20px;
        color: #000000;
        width: 440px;
        margin-top: 10px;
        height: 60px;
      }
      .bottom {
        background: url("../assets/img/jiantou.png") no-repeat;
        background-size: 100% 100%;
        width: 38px;
        height: 11px;
        margin-top: 20px;
      }
    }
    .item:hover {
      .time {
        border: 2px solid #a12916;
      }
      .day {
        color: #a12916;
      }
      .year {
        color: #a12916;
      }
      .title {
        color: #a12916;
      }
    }
  }
  ::v-deep .el-button--danger {
    background: #a12916;
    border: none;
  }
}
</style>
