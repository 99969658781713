<template>
  <div class="science">
    <div class="science_title">
      <p>{{ title }}</p>
      <span class="seeMore" @click="seeMore">查看更多</span>
    </div>
    <div class="science_piece" v-for="(item, index) in dataDetail.list" :key="index">
      <img :src="require('@/assets/img/science/'+item.url)" alt="" />
      <p @click="seeMoreSon(item)">{{item.name}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "WgScience",
  props: {
    // datas: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // },
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    dataDetail: {
      type:Object,
      default: () => {}
    }
  },
  watch: {
    columnIds: {
      async handler(val) {
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);

          this.datas = res.data.content.slice(0, 3);

        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      datas: [],
    };
  },
  methods: {
    toDetail(item) {
      this.$emit("toDetail", item);
    },
    seeMore() {
      this.$emit("seeMore", { columnId: this.columnIds, title: this.title });
    },
    seeMoreSon(item) {
      this.$emit("seeMore", { columnId: item.columnId, title: item.name });
    },
  },
};
</script>
<style lang="scss" scoped>
.science {
  width: 100%;
  height: 635px;
  background: #eeeeed;
  // padding-top: 20px;
  position: relative;
  color: #fff;
  display: flex;
}
.science_title {
  z-index: 11;
  // padding: 0 270px;
  width: 1364px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 38px;
  margin: 0 calc(50% - 682px);
  .seeMore {
    color: #ffffff;
    opacity: 0.4;
    font-size: 16px;
  }
}
.science_title p {
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  display: flex;

  align-items: center;
}
.science_title p span {
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
  opacity: 0.4;
  margin-left: 12px;
  margin-top: 10px;
}

.science_piece {
  flex: 1;
  height: 100%;
  position: relative;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
  p {
    line-height: 53px;
  position: absolute;
  z-index: 1;
  top: 228px;
  left: 225px;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 25px;
  color: #ffffff;
  writing-mode: vertical-lr;
  }
}
.seeMore {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  opacity: 0.4;
}
</style>
